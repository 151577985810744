import { createSlice } from '@reduxjs/toolkit';
import { HYDRATE } from 'next-redux-wrapper';

const initialState = {
  platforms: [
    {
      title: 'Instagram',
      url: '/images/instagram-outline.svg',
      value: 'INSTAGRAM',
    },
    {
      title: 'YouTube',
      url: '/images/youtube.svg',
      value: 'YOUTUBE',
    },
  ],
  platforms2: [
    {
      title: 'All',
      url: '',
      value: 'ALL',
    },
    {
      title: 'Instagram',
      url: '/images/instagram-outline.svg',
      value: 'INSTAGRAM',
    },
    {
      title: 'YouTube',
      url: '/images/youtube.svg',
      value: 'YOUTUBE',
    },
  ],
  selectedPlatform: {
    title: 'Instagram',
    url: '/images/instagram-outline.svg',
    value: 'INSTAGRAM',
  },
  apiParams: {
    page: 1,
    size: 50,
    sortBy: 'followers,reels_reach,engagement_rate',
    sortDir: 'DESC',
    platform: 'INSTAGRAM',
  },
  formattedFilters: [],
  platformPosts: {
    INSTAGRAM: [
      { value: 'image_posts', title: 'Image' },
      { value: 'reel_posts', title: 'Reel' },
      { value: 'story_posts', title: 'Story' },
    ],
    YOUTUBE: [
      { value: 'video_posts', title: 'Video' },
      { value: 'short_posts', title: 'Short' },
    ],
  },
  categories: [
    {
      title: 'All Categories',
      value: '',
    },
    { title: 'Entertainment', value: 'Entertainment' },
    { title: 'Music', value: 'Music' },
    { title: 'Education', value: 'Education' },
    { title: 'Gaming', value: 'Gaming' },
    { title: 'Miscellaneous', value: 'Miscellaneous' },
    { title: 'Science & Technology', value: 'Science & Technology' },
    { title: 'News & Politics', value: 'News & Politics' },
    { title: 'Religious Content', value: 'Religious Content' },
    { title: 'Comedy', value: 'Comedy' },
    { title: 'Food & Drinks', value: 'Food & Drinks' },
    { title: 'Vloging', value: 'Vloging' },
    { title: 'Health & Fitness', value: 'Health & Fitness' },
    { title: 'Autos & Vehicles', value: 'Autos & Vehicles' },
    { title: 'Finance', value: 'Finance' },
    { title: 'Fashion & Style', value: 'Fashion & Style' },
    { title: 'DIY', value: 'DIY' },
    { title: 'Kids & Animation', value: 'Kids & Animation' },
    { title: 'Sports', value: 'Sports' },
    { title: 'Animation', value: 'Animation' },
    { title: 'Infotainment', value: 'Infotainment' },
    { title: 'Beauty', value: 'Beauty' },
    { title: 'Motivational', value: 'Motivational' },
    { title: 'Travel & Leisure', value: 'Travel & Leisure' },
    { title: 'Astrology', value: 'Astrology' },
    { title: 'Photography & Editing', value: 'Photography & Editing' },
    {
      title: 'Agriculture & Allied Sectors',
      value: 'Agriculture & Allied Sectors',
    },
    { title: 'Movie & Shows', value: 'Movie & Shows' },
    { title: 'Pets & Animals', value: 'Pets & Animals' },
    { title: 'IT & ITES', value: 'IT & ITES' },
    { title: 'Real Estate', value: 'Real Estate' },
    { title: 'Supernatural', value: 'Supernatural' },
    { title: 'Devotional', value: 'Devotional' },
    { title: 'Defence', value: 'Defence' },
    { title: 'Blogs and Travel', value: 'Blogs and Travel' },
    { title: 'People & Culture', value: 'People & Culture' },
    { title: 'Reviews', value: 'Reviews' },
    { title: 'Adult', value: 'Adult' },
    { title: 'Family & Parenting', value: 'Family & Parenting' },
    { title: 'Government', value: 'Government' },
    { title: 'Book', value: 'Book' },
    { title: 'Non profits', value: 'Non profits' },
    { title: 'Arts & Craft', value: 'Arts & Craft' },
    { title: 'Events', value: 'Events' },
    { title: 'Electronics', value: 'Electronics' },
    { title: 'Films', value: 'Films' },
    { title: 'Legal', value: 'Legal' },
  ],
  languageList: [
    { value: 'all', title: 'All Language' },
    { value: 'hi', title: 'Hindi' },
    { value: 'en', title: 'English' },
    { value: 'ta', title: 'Tamil' },
    { value: 'bn', title: 'Bengali' },
    { value: 'te', title: 'Telugu' },
    { value: 'ml', title: 'Malyalam' },
    { value: 'mr', title: 'Marathi' },
    { value: 'gu', title: 'Gujarati' },
    { value: 'pa', title: 'Punjabi' },
    { value: 'or', title: 'Odia' },
    { value: 'ka', title: 'Kannada' },
  ],
  languageMapper: {
    hi: 'Hindi',
    en: 'English',
    ta: 'Tamil',
    bn: 'Bengali',
    te: 'Telugu',
    ml: 'Malyalam',
    mr: 'Marathi',
    gu: 'Gujarati',
    pa: 'Punjabi',
    or: 'Odia',
    ka: 'Kannada',
  },
  countryMapper: {
    IN: 'India',
  },
  ageBuckets: [
    { value: '13-17', primaryLabel: '13yrs-17yrs' },
    { value: '18-24', primaryLabel: '18yrs-24yrs' },
    { value: '25-34', primaryLabel: '25yrs-34yrs' },
    { value: '35-44', primaryLabel: '35yrs-44yrs' },
    { value: '45-54', primaryLabel: '45yrs-54yrs' },
    { value: '55-64', primaryLabel: '55yrs-64yrs' },
    { value: '65+', primaryLabel: '>64yrs' },
  ],
  sorting: {
    YOUTUBE: [
      { value: 'followers', title: 'Followers' },
      { value: 'views', title: 'Views' },
    ],
    INSTAGRAM: [
      { value: 'followers', title: 'Followers' },
      { value: 'plays', title: 'Plays' },
    ],
    ALL: [
      { value: 'followers', title: 'Followers' },
      { value: 'views', title: 'Views' },
    ],
  },
  selectedCategory: {
    title: 'All Categories',
    value: '',
  },
  discoveryTableModal: {
    show: false,
    reRender: 1,
  },
  rawFilters: {
    audience_location: {
      filterTypes: [
        {
          GTE: '',
        },
      ],
      fields: [
        {
          value: '',
        },
        {
          value: '',
        },
      ],
    },
    followers: {
      filterTypes: [
        {
          GTE: '',
        },
        {
          LTE: '',
        },
      ],
    },
    audience_age: {
      filterTypes: [
        {
          GTE: '',
        },
        {
          LTE: '',
        },
      ],
      fields: [
        {
          value: '',
        },
      ],
    },
    audience_gender: {
      filterTypes: [
        {
          GTE: '',
        },
      ],
      fields: [
        {
          value: '',
        },
      ],
    },
    categories: {
      filterTypes: [
        {
          ARRAY: '',
        },
      ],
    },
    keywords: {
      filterTypes: [
        {
          ARRAY: '',
        },
        {
          EQ: '',
        },
      ],
    },
    search_phrase: {
      filterTypes: [
        {
          EQ: '',
        },
      ],
    },
    location: {
      filterTypes: [
        {
          ARRAY: '',
        },
      ],
    },
    gender: {
      filterTypes: [
        {
          EQ: '',
        },
      ],
    },
    languages: {
      filterTypes: [
        {
          ARRAY: '',
        },
      ],
    },
    flag_contact_info_available: {
      filterTypes: [
        {
          BOOL: '',
        },
      ],
    },
    est_post_price: {
      filterTypes: [
        {
          GTE: '',
        },
        {
          LTE: '',
        },
      ],
      fields: [
        {
          value: '',
        },
      ],
    },
    avg_likes: {
      filterTypes: [
        {
          GTE: '',
        },
        {
          LTE: '',
        },
      ],
    },
    est_reach: {
      filterTypes: [
        {
          GTE: '',
        },
        {
          LTE: '',
        },
      ],
      fields: [
        {
          value: '',
        },
      ],
    },
    est_impressions: {
      filterTypes: [
        {
          GTE: '',
        },
        {
          LTE: '',
        },
      ],
      fields: [
        {
          value: '',
        },
      ],
    },
  },
  searchSuggestionModal: {
    flag: false,
    loading: false,
    data: [],
  },
  selectedLocations: [],
  selectedInfluencerSize: {},
  gradeColors: {
    Excellent: {
      color: '#985EF3',
    },
    'Very Good': {
      color: '#3DA634',
    },
    Good: {
      color: '#F78859',
    },
    Average: {
      color: '#FFC400',
    },
    Poor: {
      color: '#FC5656',
    },
  },
};

export const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    // Action to add
    selectPlatform: (state, action) => {
      state.selectedPlatform = action.payload;
      state.apiParams.platform = action.payload.value;
    },
    selectPlatformByID: (state, action) => {
      if (action.payload == '1') {
        state.selectedPlatform = {
          title: 'Instagram',
          url: '/images/instagram-outline.svg',
          value: 'INSTAGRAM',
        };
        state.apiParams.platform = 'INSTAGRAM';
      } else {
        state.selectedPlatform = {
          title: 'YouTube',
          url: '/images/youtube.svg',
          value: 'YOUTUBE',
        };
        state.apiParams.platform = 'YOUTUBE';
      }
    },
    setKeyword: (state, action) => {
      state.rawFilters.keywords.filterTypes[0].ARRAY = action.payload;
    },
    setApiParams: (state, action) => {
      state.apiParams = action.payload;
    },
    setSortBy: (state, action) => {
      state.apiParams.sortBy = action.payload;
    },
    setSortDir: (state, action) => {
      state.apiParams.sortDir = action.payload;
    },
    formatFilters1: (state) => {
      const newFormattedFilters = [];
      const filterFields = Object.keys(state.rawFilters);
      filterFields.forEach((key) => {
        if (
          state.rawFilters[key].fields &&
          state.rawFilters[key].fields.length > 0
        ) {
          let newKey = key;
          state.rawFilters[key].fields.forEach((node) => {
            if (node.value && node.value.length > 0) newKey += '.' + node.value;
          });
          if (
            state.rawFilters[key].filterTypes &&
            state.rawFilters[key].filterTypes.length > 0
          ) {
            state.rawFilters[key].filterTypes.forEach((node) => {
              const filterFields = Object.keys(node);
              filterFields.forEach((node2) => {
                if (node[node2] && node[node2].length > 0)
                  newFormattedFilters.push({
                    field: newKey,
                    value: node[node2],
                    filterType: node2,
                  });
              });
            });
          }
        } else if (
          state.rawFilters[key].filterTypes &&
          state.rawFilters[key].filterTypes.length > 0
        ) {
          state.rawFilters[key].filterTypes.forEach((node) => {
            const filterFields = Object.keys(node);
            filterFields.forEach((node2) => {
              if (node[node2] && node[node2].length > 0)
                newFormattedFilters.push({
                  field: key,
                  value: node[node2],
                  filterType: node2,
                });
            });
          });
        }
      });
      state.formattedFilters = newFormattedFilters;
    },
    formatFilters: (state) => {
      const newFormattedFilters = [];
      const rawFilterKeys = Object.keys(state.rawFilters);
      rawFilterKeys.forEach((key) => {
        state.rawFilters[key].filterTypes.forEach((node) => {
          const nodeKeys = Object.keys(node);
          let field = key;

          if (node['filterField'] && node['filterField'].length > 0)
            field += '.' + node['filterField'];

          nodeKeys.forEach((nodeKey) => {
            if (
              nodeKey != 'filterField' &&
              node[nodeKey] &&
              node[nodeKey].length > 0
            )
              newFormattedFilters.push({
                field,
                value: node[nodeKey],
                filterType: nodeKey,
              });
          });
        });
      });
      state.formattedFilters = newFormattedFilters;
    },
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    selectCategory: (state, action) => {
      state.selectedCategory = action.payload;
    },
    setDiscoveryTableModal: (state, action) => {
      state.discoveryTableModal.show = action.payload.show;
      if (action.payload.reRender)
        state.discoveryTableModal.reRender = action.payload.reRender;
    },
    setSearchSuggestion: (state, action) => {
      state.searchSuggestionModal.flag = action.payload.status;
      state.searchSuggestionModal.data = action.payload.data;
      state.searchSuggestionModal.loading = action.payload.loading;
    },
    updateFilters: (state, action) => {
      if (action.payload.key) {
        if (action.payload.filterTypes)
          state.rawFilters[action.payload.key].filterTypes =
            action.payload.filterTypes;
        if (action.payload.fields)
          state.rawFilters[action.payload.key].fields = action.payload.fields;
      }
    },
    setFilters: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        action.payload.map((node) => {
          const fields = node.field.split('.');
          if (fields && fields.length > 1) {
            fields.forEach((field, index) => {
              if (index > 0)
                state.rawFilters[fields[0]].fields.push({
                  value: field,
                });
            });
          }
          // state.rawFilters[fields[0]].filterTypes.push({
          //   node.filterType: node.value
          // })
        });
      }
    },
    resetPlatform: (state) => {
      state.selectedPlatform = {
        title: 'Instagram',
        url: '/images/instagram-outline.svg',
        value: 'INSTAGRAM',
      };
      state.apiParams.platform = 'INSTAGRAM';
    },
    resetPlatformFilters: (state) => {
      state.rawFilters.est_post_price = {
        filterTypes: [],
      };
      state.rawFilters.est_reach = {
        filterTypes: [],
      };
      state.rawFilters.est_impressions = {
        filterTypes: [],
      };
    },
    setRawFiltersFromResponse: (state, action) => {
      action.payload.forEach((x) => {
        if (x.field.includes('.')) {
          let tempArr = x.field.split('.');
          let tempKey = tempArr[0];
          let tempFilterField = tempArr.slice(1).join('.');
          state.rawFilters[tempKey].filterTypes.push({
            filterField: tempFilterField,
            [x.filterType]: x.value,
          });
        } else {
          state.rawFilters[x.field].filterTypes.push({
            [x.filterType]: x.value,
          });
        }
      });
    },
    resetRawFilters: (state) => {
      state.rawFilters = {
        audience_location: {
          filterTypes: [
            {
              GTE: '',
            },
          ],
          fields: [
            {
              value: '',
            },
          ],
        },
        followers: {
          filterTypes: [
            {
              GTE: '',
            },
            {
              LTE: '',
            },
          ],
        },
        audience_age: {
          filterTypes: [
            {
              GTE: '',
            },
            {
              LTE: '',
            },
          ],
          fields: [
            {
              value: '',
            },
          ],
        },
        audience_gender: {
          filterTypes: [
            {
              GTE: '',
            },
          ],
          fields: [
            {
              value: '',
            },
          ],
        },
        categories: {
          filterTypes: [
            {
              ARRAY: '',
            },
          ],
        },
        keywords: {
          filterTypes: [
            {
              ARRAY: '',
            },
            {
              EQ: '',
            },
          ],
        },
        search_phrase: {
          filterTypes: [
            {
              EQ: '',
            },
          ],
        },
        location: {
          filterTypes: [
            {
              ARRAY: '',
            },
          ],
        },
        gender: {
          filterTypes: [
            {
              EQ: '',
            },
          ],
        },
        languages: {
          filterTypes: [
            {
              ARRAY: '',
            },
          ],
        },
        flag_contact_info_available: {
          filterTypes: [
            {
              BOOL: '',
            },
          ],
        },
        est_post_price: {
          filterTypes: [
            {
              GTE: '',
            },
            {
              LTE: '',
            },
          ],
          fields: [
            {
              value: '',
            },
          ],
        },
        avg_likes: {
          filterTypes: [
            {
              GTE: '',
            },
            {
              LTE: '',
            },
          ],
        },
        est_reach: {
          filterTypes: [
            {
              GTE: '',
            },
            {
              LTE: '',
            },
          ],
          fields: [
            {
              value: '',
            },
          ],
        },
        est_impressions: {
          filterTypes: [
            {
              GTE: '',
            },
            {
              LTE: '',
            },
          ],
          fields: [
            {
              value: '',
            },
          ],
        },
      };
    },
    setLanguageList: (state, action) => {
      state.languageList = action.payload;
    },

    // Special reducer for hydrating the state
    extraReducers: {
      [HYDRATE]: (state, action) => {
        return {
          ...state,
          ...action.payload.filters,
        };
      },
    },
  },
});

export const {
  selectPlatform,
  setKeyword,
  setSortBy,
  setSortDir,
  formatFilters,
  setCategories,
  selectCategory,
  setFilters,
  setSearchSuggestion,
  updateFilters,
  resetPlatformFilters,
  resetPlatform,
  setDiscoveryTableModal,
  resetRawFilters,
  setRawFiltersFromResponse,
  setLanguageList,
  selectPlatformByID,
} = filtersSlice.actions;

export default filtersSlice.reducer;
